import ChevronsUpDownIcon from "virtual:icons/lucide/chevrons-up-down";
import type { ComponentPropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "#app/hooks/use-media-query.js";
import { cx } from "#cva.config.js";
import { Button } from "./button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./dropdown";

export function LanguageSwitcher(props: ComponentPropsWithoutRef<typeof DropdownMenuTrigger>) {
	const { t, i18n } = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 1024px) and (max-width: 1350px)");

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	const languagesList = {
		languages: [
			{
				value: "es",
				label: "Español",
			},
			{
				value: "pt",
				label: "Português",
			},
			{
				value: "en",
				label: "English",
			},
			{
				value: "ru",
				label: "Русский",
			},
		],
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild {...props}>
				<Button $variant="outline">
					<span>{isDesktop ? t(i18n.language).slice(0, 2) : t(i18n.language)}</span>
					<ChevronsUpDownIcon className="lucide lucide-chevrons-up-down ml-0.5 size-4 shrink-0 opacity-50 dark:text-zinc-300 sm:dark:text-zinc-400 md:ml-2 md:size-4" />
				</Button>
			</DropdownMenuTrigger>

			<DropdownMenuContent
				align="end"
				className={cx(
					"flex origin-top-right flex-col rounded-xl border border-gray-200/75 bg-background p-2 text-[inherit] shadow-xl dark:border-zinc-700/50 dark:bg-[#202035] focus:outline-none w-auto",
				)}
			>
				{languagesList.languages.map((language) => (
					<DropdownMenuItem
						asChild
						key={language.value}
						className={cx(
							"inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none",
							language.value === i18n.language
								? "bg-primary text-white hover:bg-primary/90 dark:hover:bg-primary/90"
								: "hover:bg-slate-100 dark:hover:bg-zinc-700/50",
						)}
						onClick={() => {
							changeLanguage(language.value);
						}}
					>
						<span>{isDesktop ? language.label.slice(0, 2) : language.label}</span>
					</DropdownMenuItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
